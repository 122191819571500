<template>
  <CModal id="BulkImportLogisticsData" class="ActionModel" :show.sync="$parent.ToggleModal" :centered="true" size="lg" :closeOnBackdrop="!Submit"
          @update:show="HandleHideModal">
    <template v-if="PageStatus">
      <CAlert color="info">
        <i class="fas fa-info-circle mr-2" />{{ $t('Order.LogisticsTracking.Info') }}
      </CAlert>
      <CAlert color="warning">
        <h6><i class="fas fa-exclamation-circle mr-2" />注意事項：</h6>
        <ul class="pl-3 mb-0">
          <li>{{ $t('Order.LogisticsTracking.Description[0]') }}<span class="text-danger font-weight-bold">{{ $t('Order.OrderNum') }}、{{ $t('Order.LogisticsTracking.Provider') }}、{{ $t('Order.LogisticsTracking.TrackingNum') }}、{{ $t('Order.LogisticsTracking.ShippingDate') }}</span>{{ $t('Order.LogisticsTracking.Description[1]') }}</li>
          <li><span class="text-danger font-weight-bold">{{ $t('Order.LogisticsTracking.TrackUrl') }}</span>{{ $t('Order.LogisticsTracking.Description[2]') }}</li>
          <li>{{ $t('Order.LogisticsTracking.Description[4]') }}</li>
          <li>{{ $t('Order.LogisticsTracking.Description[7]') }}</li>
        </ul>
      </CAlert>
      <CInputFile @change="UploadFile" />
    </template>
    <template v-else>
      <p>
        {{ $t('Order.LogisticsTracking.Description[5]') }}<br>
        <span style="color: #f9162b">{{ $t('Order.LogisticsTracking.Description[6]') }}</span>
      </p>
      <CDataTable
        id="DataList"
        :items="UploadedTable"
        :fields="Field"
        :noItemsView="noItemsView"
        responsive
      />
      <CSelect :label="$t('Order.LogisticsTracking.ChangeStatus')" :options="ChangeStatusOption" v-model="ChangeStatus" :value.sync="ChangeStatus" :placeholder="$t('Global.PleaseSelect')" horizontal/>
      <CSelect horizontal :label="$t('Logistics.System')" :options="LogisticsSystem" v-model="Logistics.System" :value.sync="Logistics.System" :placeholder="$t('Global.PleaseSelect')"/>
    </template>
    <CElementCover v-if="(Loading === true || Submit === true)" :opacity="0.75" class="py-5">
      <CSpinner color="info"/>
    </CElementCover>
    <template #header-wrapper>
      <header class="modal-header border-bottom-0">
        <h5 class="modal-title">{{ $t('Order.LogisticsTracking.BulkImport') }}</h5>
        <CButtonClose @click="HandleHideModal()"/>
      </header>
    </template>
    <template #footer-wrapper>
      <footer class="modal-footer border-top-0">
        <CButton @click="DownloadExample" color="info">{{ $t('Order.LogisticsTracking.DownloadExample') }}</CButton>
        <template v-if="PageStatus">
          <CButton id="Upload" @click="ChangePage()" color="success" :disabled="!FileUploaded">
            {{ $t('Global.Upload') }}
          </CButton>
        </template>
        <template v-else>
          <CButton @click="ChangePage()" color="light">{{ $t('Global.Cancel') }}</CButton>
          <CButton id="CreateLogisticsOrderSubmit" @click="ConfirmUpload()" color="success">
            {{ $t('Global.Confirm') }}
          </CButton>
        </template>
      </footer>
    </template>
  </CModal>
</template>

<script>
export default {
  name: 'BulkImportLogisticsData',
  data() {
    return {
      Loading: false,
      Submit: false,
      PageStatus: true,
      ChangeStatus: 'P',
      FileUploaded: false,
      UploadedTable: [],
      noItemsView: {
        noResults: this.$t('Order.NoResults'),
        noItems: this.$t('Order.NoItems')
      },
      Logistics: {
        System: 'CUSTOM-LOGISTICS'
      }
    }
  },
  computed: {
    Field() {
      return [
        {key: 'OrderNo', label: this.$t('Order.OrderNum')},
        {key: 'Provider', label: this.$t('Order.LogisticsTracking.Provider')},
        {key: 'TrackURL', label: this.$t('Order.LogisticsTracking.TrackUrl')},
        {key: 'BookingNo', label: this.$t('Order.LogisticsTracking.TrackingNum')},
        {key: 'TradeNo', label: this.$t('Order.LogisticsTracking.TradeNo')},
        {key: 'ShippingDate', label: this.$t('Order.LogisticsTracking.ShippingDate')},
        {key: 'AssignDate', label: this.$t('Order.LogisticsTracking.AssignDate')}
      ]
    },
    ChangeStatusOption () {
      return Object.keys(this.$t('Order.LogisticsStatusList')).filter(status => ['P', 'H', 'U', 'O', 'S', 'Y'].includes(status)).map(status => {
        return {
          value: status,
          label: status === 'P' ? this.$t('Order.LogisticsTracking.NoChange') : this.$t('Order.LogisticsStatusList.' + status),
        }
      })
    },
    LogisticsSystem () {
      return Object.keys(this.$t('Logistics.LogisticsSystem')).filter(system => system !== 'CUSTOM').map(system => {
        return {
          value: system,
          label: this.$t('Logistics.LogisticsSystem')[system]
        }
      })
    },
  },
  methods: {
    HandleHideModal() {
      this.$parent.ToggleModal = false
    },
    UploadFile(event) {
      const reader = new FileReader()
      reader.onload = (e) => {
        const data = e.target.result
        const wb = this.$xlsx.read(data, {type: 'binary', cellDates: true})
        const ws = this.$xlsx.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]])
        ws.forEach(el => {
          this.UploadedTable.push({
            OrderNo: el[this.$t('Order.OrderNum')] || '',
            Provider: el[this.$t('Order.LogisticsTracking.Provider')] || '',
            TrackURL: el[this.$t('Order.LogisticsTracking.TrackUrl')] || '',
            BookingNo: (el[this.$t('Order.LogisticsTracking.TrackingNum')] || '').toString() || '',
            TradeNo: (el[this.$t('Order.LogisticsTracking.TradeNo')] || '').toString() || '',
            ShippingDate: el[this.$t('Order.LogisticsTracking.ShippingDate')] ? this.$dayjs(el[this.$t('Order.LogisticsTracking.ShippingDate')]).format('YYYY-MM-DD') : '',
            AssignDate: el[this.$t('Order.LogisticsTracking.AssignDate')] ? this.$dayjs(el[this.$t('Order.LogisticsTracking.AssignDate')]).format('YYYY-MM-DD') : ''
          })
        })
        this.FileUploaded = true
      }
      reader.readAsBinaryString(event[0])
    },
    ChangePage() {
      this.PageStatus = !this.PageStatus
      this.FileUploaded = false
    },
    Cancel() {
      this.PageStatus = true
      this.FileUploaded = false
      this.UploadedTable = []
    },
    ConfirmUpload() {
      this.Submit = true
      this.$Progress.start()
      const UploadData = this.UploadedTable.map(el => {
        let BookingNo = el.BookingNo.replace(/[\uFF01-\uFF5E]/g, ',').replace(/、/g, ',').replace(/，/g, ',')
        let TradeNo = el.TradeNo.replace(/[\uFF01-\uFF5E]/g, ',').replace(/、/g, ',').replace(/，/g, ',')
        BookingNo = BookingNo.split(',')
        TradeNo = TradeNo.split(',')
        const LogisticsPackage = BookingNo.map((bookingNo, index) => {
          return {
            BookingNo: bookingNo, //物流單號或托運單號
            IsPayment: true,
            LabelImage: '',
            MerchantOrderNo: el.OrderNo,
            Method: el.Provider, //配送方式
            OrderNo: '',
            Quantity: '1',
            System: this.Logistics.System,
            TradeNo: TradeNo[index] || bookingNo, //物流單號
            Type: 'EXPRESS',
            TrackURL: el.TrackURL, //物流公司的追蹤貨態網址
            ShippingDate: el.ShippingDate,
            AssignDate: el.AssignDate
          }
        })
        const updateData = {
          LogisticsData: {
            BookingNo: BookingNo.length > 0 ? BookingNo[0] : '', //物流單號或托運單號
            IsPayment: true,
            LabelImage: '',
            MerchantOrderNo: el.OrderNo,
            Method: el.Provider, //配送方式
            OrderNo: '',
            Quantity: '1',
            System: this.Logistics.System.toLowerCase(),
            TradeNo: TradeNo.length > 0 ? TradeNo[0] : '', //物流單號
            Type: 'EXPRESS',
            TrackURL: el.TrackURL, //物流公司的追蹤貨態網址
            ShippingDate: el.ShippingDate,
            AssignDate: el.AssignDate
          },
          LogisticsPackage
        }
        if (this.ChangeStatus !== 'P') {
          updateData['Status.Logistics'] = this.ChangeStatus
        }
        return {
          orderNum: el.OrderNo,
          updateData
        }
      })
      return this.$store.dispatch('InnerRequest', {
        endPoint: '/import',
        url: '/order/bulkImportLogisticsData',
        method: 'post',
        data: {
          origin: 'Manage',
          UploadData
        }
      }).then((result) => {
        this.Submit = false
        this.$Progress.finish()
        this.HandleHideModal()
        this.$parent.GetList()
        this.$notify({
          group: 'notify',
          title: this.$t('Message.ExecuteFinish'),
          text: this.$t('Message.Order/BulkCreateImportLogisticsDataFinish', { success: result.data.success, fail: result.data.fail, total: result.data.total}),
          type: 'info',
          duration: 10000
        })
      }).catch((error) => {
        this.$notify({
          group: 'notify',
          title: this.$t('Message.ExecuteFail'),
          text: this.$t('Message.Order/BulkCreateImportLogisticsDataFail'),
          type: 'info',
          duration: 10000
        })
        throw error
      })
    },
    DownloadExample() {
      const Headers = ['OrderNo', 'Provider', 'TrackURL', 'BookingNo', 'ShippingDate', 'AssignDate', 'TradeNo']
      const sheet = [
        {
          OrderNo: this.$t('Order.OrderNum'),
          Provider: this.$t('Order.LogisticsTracking.Provider'),
          TrackURL: this.$t('Order.LogisticsTracking.TrackUrl'),
          BookingNo: this.$t('Order.LogisticsTracking.TrackingNum'),
          ShippingDate: this.$t('Order.LogisticsTracking.ShippingDate'),
          AssignDate: this.$t('Order.LogisticsTracking.AssignDate'),
          TradeNo: this.$t('Order.LogisticsTracking.TradeNo')
        },
        {
          OrderNo: '8621071300002',
          Provider: '黑貓宅急便',
          TrackURL: 'http://www.t-cat.com.tw/Inquire/Trace.aspx',
          BookingNo: '123456',
          ShippingDate: this.$dayjs().format('YYYY-MM-DD'),
          AssignDate: '',
          TradeNo: '0012345'
        }
      ]
      const wb = this.$xlsx.utils.book_new()
      const ws = this.$xlsx.utils.json_to_sheet(sheet, {header: Headers, skipHeader: true})
      this.$xlsx.utils.book_append_sheet(wb, ws)
      const ExampleFile = this.$xlsx.write(wb, {type: 'base64', bookType: 'xlsx'})
      const BinaryString = window.atob(ExampleFile)
      const Bytes = new Uint8Array(BinaryString.length)
      for (let index = 0; index < BinaryString.length; index++) {
        Bytes[index] = BinaryString.charCodeAt(index)
      }
      const Data = new Blob([Bytes], {type: 'application/octet-stream'})
      const blobURL = URL.createObjectURL(Data)
      const tempLink = document.createElement('a')
      tempLink.style.display = 'none'
      tempLink.href = blobURL
      tempLink.setAttribute('download', 'example.xlsx', '_blank')
      document.body.appendChild(tempLink)
      tempLink.click()
      document.body.removeChild(tempLink)
      window.URL.revokeObjectURL(blobURL)
    }
  }
}
</script>

<style lang="scss">
#BulkImportLogisticsData {
  label {
    font-weight: bold;
  }
  .table {
    thead th {
      white-space: nowrap;
    }
    td {
      white-space: nowrap;
    }
  }
}
</style>
